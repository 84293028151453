<mat-card>
  <mat-card-header>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput value="{{ decodedToken.name }}" readonly="readonly" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>NIM</mat-label>
      <input matInput value="{{ decodedToken.nim }}" readonly="readonly" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>IPK</mat-label>
      <input matInput value="{{ decodedToken.ipk }}" readonly="readonly" />
    </mat-form-field>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-card-content>
    <mat-label>Pilihlah urutan minat 1 - 5 sesuai prioritas saudara.</mat-label>
    <!-- <button mat-raised-button color="primary">
            <mat-icon>add</mat-icon>Tambah Peminatan
        </button> -->
    <form [formGroup]="formPeminatanMahasiswa" #formDirective="ngForm"
      (ngSubmit)="onSubmitPeminatanMahasiswa(formPeminatanMahasiswa.value)" novalidate>
      <mat-form-field class="mat-75">
        <mat-label>Pilih Peminatan</mat-label>
        <mat-select name="idPeminatan" [formControlName]="'idPeminatan'" id="idPeminatan" name="idPeminatan">
          <mat-option value="none" disabled="disabled">None</mat-option>
          <mat-option *ngFor="let item of peminatan$ | async" [value]="item.id">{{ item.nama }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-25">
        <mat-label>Urutan Peminatan</mat-label>
        <mat-select name="urutanMinat" formControlName="urutanMinat" id="urutanMinat">
          <mat-option value="none" disabled="disabled">None</mat-option>
          <mat-option *ngFor="let item of peminatan$ | async; let i = index" [value]="i+1">{{i+1}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-label>Sudah Punya Surat Rekomendasi? </mat-label>
            <mat-radio-group name="haveRecommendation" formControlName="haveRecommendation" id="haveRecommendation">
                <mat-radio-button value="ya">Ya</mat-radio-button>
                <mat-radio-button value="tidak">Tidak</mat-radio-button>
            </mat-radio-group> -->

      <!-- <mat-label>Surat Rekomendasi</mat-label>
            <mat-checkbox name="haveRecommendation" formControlName="haveRecommendation" id="haveRecommendation"
                color="warn">Apakah
                saudara sudah memiliki surat rekomendasi dosen pembimbing?</mat-checkbox> -->

      <!-- <mat-checkbox name="isFinalSubmit" formControlName="isFinalSubmit" id="isFinalSubmit" color="warn">Apakah
                anda setuju untuk mengirim pilihan
                peminatan? Jika iya, mahasiswa tidak dapat mengadukan keluhan dikemudian hari.</mat-checkbox> -->

      <button color="accent" mat-raised-button type="submit">
        <span>Submit</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="formDataPeminatanMahasiswa" #formDirective="ngForm"
      (ngSubmit)="onSubmitDataPeminatanMahasiswa(formDataPeminatanMahasiswa.value)" novalidate>

      <mat-label>Sudah Punya Surat Rekomendasi? </mat-label>
      <br />
      <mat-radio-group name="haveRecommendation" formControlName="haveRecommendation" id="haveRecommendation">
        <mat-radio-button value="ya">Ya</mat-radio-button>
        <mat-radio-button value="tidak">Tidak</mat-radio-button>
      </mat-radio-group>
      
      <br />
      <mat-checkbox name="isFinalSubmit" formControlName="isFinalSubmit" id="isFinalSubmit" color="warn">Apakah
        anda setuju untuk mengirim pilihan
        peminatan? Jika iya, mahasiswa tidak dapat mengadukan keluhan dikemudian hari.</mat-checkbox>

      <br />
      <button color="accent" mat-raised-button type="submit">
        <span>Submit</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="formUploadFileSuratRekomendasi" #formDirective="ngForm"
      (ngSubmit)="onSubmitUploadFileSuratRekomendasi(formUploadFileSuratRekomendasi.value)" novalidate>

      <mat-label>Upload Surat Rekomendasi</mat-label>
      <br />
      <mat-label>*Format file nama_nim (jpg/png) | ukuran file tidak boleh melebihi 3 MB</mat-label>
      <br />
      <input type="file" #filePicker (change)="onImagePicked($event)">

      <br />
      <button color="accent" mat-raised-button type="submit">
        <span>Submit</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content class="table-responsive">
    <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="no">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element; let i = index">{{i+1}}</td>
      </ng-container>

      <ng-container matColumnDef="namapeminatan">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Peminatan</th>
        <td mat-cell *matCellDef="let element">{{element.namaPeminatan}}</td>
      </ng-container>

      <ng-container matColumnDef="urutanMinat">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Urutan Peminatan</th>
        <td mat-cell *matCellDef="let element">{{element.urutanMinat}}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="haveRecommendation">
            <th mat-sort-header mat-header-cell *matHeaderCellDef> Sudah Punya Surat Rekomendasi</th>
            <td mat-cell *matCellDef="let element">{{element.haveRecommendation === 1 ? "Ya" : "Tidak"}}</td>
          </ng-container>

          <ng-container matColumnDef="isFinalSubmit">
            <th mat-sort-header mat-header-cell *matHeaderCellDef> Submit Final Peminatan</th>
            <td mat-cell *matCellDef="let element">{{element.isFinalSubmit === 1 ? "Ya" : "Tidak"}}</td>
          </ng-container> -->

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let element">

          <!-- <button (click)="submitFinalisePeminatanMahasiswa(element.id)" mat-raised-button color="primary">Submit Peminatan</button> -->
          <button (click)="deletePeminatanMahasiswa(element.id)" mat-raised-button color="warn">Delete</button>
          <!-- <button (click)="detailcustomer(element.id)" mat-raised-button color="primary">Detail</button> -->
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>

    </table>

  </mat-card-content>
  <br>
  <mat-card-footer>

    <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>

  </mat-card-footer>
</mat-card>