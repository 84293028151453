
<form [formGroup]="formFinalSubmit" (ngSubmit)="onSubmitFinalisePeminatanMahasiswa(formFinalSubmit.value, inputdata.id)">
    <h1 mat-dialog-title>{{inputdata.title}}</h1>
    <div mat-dialog-content>
        <mat-checkbox formControlName="isFinalSubmit" color="warn">Apakah anda setuju untuk mengirim pilihan peminatan? Jika iya, mahasiswa tidak dapat mengadukan keluhan dikemudian hari.</mat-checkbox>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary">Yes</button>
        <a (click)="closePopUp()" mat-raised-button color="warn">Close</a>
    </div>
</form>