<mat-card>
  <mat-card-header>
    <mat-form-field>
      <input matInput (keyup)="filterChange($event)" placeholder="Enter the text" />
    </mat-form-field>
    <button style="margin-left: 60%;" (click)="addMahasiswa()" mat-raised-button color="primary">Tambah Mahasiswa
      (+)</button>

  </mat-card-header>
  <mat-card-content>
    <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="no">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element; let i = index">{{i+1}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mahasiswa</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="nim">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Nim</th>
        <td mat-cell *matCellDef="let element">{{element.nim}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <ng-container matColumnDef="ipk">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> IPK</th>
        <td mat-cell *matCellDef="let element">{{element.ipk}}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="status">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container> -->

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let element">

          <button (click)="editMahasiswa(element.id)" mat-raised-button color="primary">Edit</button>
          <button (click)="deleteMahasiswa(element.id)" mat-raised-button color="warn">Delete</button>
          <button (click)="detailMahasiswa(element.id)" mat-raised-button color="primary">Detail</button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>

    </table>

  </mat-card-content>
  <br>
  <mat-card-footer>

    <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>

  </mat-card-footer>
</mat-card>