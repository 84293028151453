<mat-card>
  <mat-card-content>
    <button (click)="generatePeminatanBasedOnHaveRecommendation()" mat-raised-button color="primary">Generate Peminatan Berdasarkan Surat Rekomendasi</button>
  </mat-card-content>
  <mat-card-content>
    <form [formGroup]="formGeneratePeminatan" #formDirective="ngForm"
      (ngSubmit)="onSubmitGeneratePeminatan(formGeneratePeminatan.value)" novalidate>
      <mat-form-field class="mat-75">
        <mat-label>Minat</mat-label>
        <mat-select name="nama" formControlName="nama">
          <mat-option value="none" disabled="disabled">None</mat-option>
          <mat-option *ngFor="let item of peminatan$ | async" [value]="item.nama">{{ item.nama }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button color="accent" mat-raised-button type="submit">
        <span>Generate Peminatan</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-form-field>
      <input matInput (keyup)="filterChange($event)" placeholder="Enter the text filter" />
    </mat-form-field>

    <!-- <button style="margin-left: 60%;" (click)="addMahasiswa()" mat-raised-button color="primary">Tambah Mahasiswa
        (+)</button> -->
  </mat-card-header>

  <mat-card-content>
    <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="no">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element; let i = index">{{i+1}}</td>
      </ng-container>

      <ng-container matColumnDef="namaMahasiswa">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mahasiswa</th>
        <td mat-cell *matCellDef="let element">{{element.namaUser}}</td>
      </ng-container>

      <ng-container matColumnDef="ipk">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> IPK</th>
        <td mat-cell *matCellDef="let element">{{element.ipk}}</td>
      </ng-container>

      <ng-container matColumnDef="namaPeminatan">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Peminatan</th>
        <td mat-cell *matCellDef="let element">{{element.namaPeminatan}}</td>
      </ng-container>

      <ng-container matColumnDef="urutanMinat">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Urutan Minat</th>
        <td mat-cell *matCellDef="let element">{{element.urutanPeminatan}}</td>
      </ng-container>

      <ng-container matColumnDef="suratRekomendasi">
        <th mat-sort-header mat-header-cell *matHeaderCellDef> Punya Surat Rekomendasi</th>
        <td mat-cell *matCellDef="let element">{{element.haveRecommendation === 1 ? 'Ya' : 'Tidak'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>

    </table>

  </mat-card-content>
  <br>
  <mat-card-footer>

    <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>

  </mat-card-footer>
</mat-card>