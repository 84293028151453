
<mat-card class="card-class">
    <mat-card-header>
        <mat-card-title>Nihira Techiees</mat-card-title>
        <mat-card-subtitle>Angular 15 - Material UI</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-sm-image src="https://angular.io/assets/images/logos/angular/angular.svg"/>
    <mat-card-content>
        <p>
            Angular is an application-design framework and development platform for creating efficient and sophisticated single-page apps.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button><mat-icon>thumb_up</mat-icon> Like</button>
        <button mat-button><mat-icon>share</mat-icon> Share</button>
    </mat-card-actions>
    <mat-card-footer></mat-card-footer>
</mat-card>

<mat-card class="card-class">
    <mat-card-header>
        <mat-card-title>Nihira Techiees</mat-card-title>
        <mat-card-subtitle>Angular 15 - Material UI</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-md-image src="https://angular.io/assets/images/logos/angular/angular.svg"/>
    <mat-card-content>
        <p>
            Angular is an application-design framework and development platform for creating efficient and sophisticated single-page apps.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button><mat-icon>thumb_up</mat-icon> Like</button>
        <button mat-button><mat-icon>share</mat-icon> Share</button>
    </mat-card-actions>
    <mat-card-footer></mat-card-footer>
</mat-card>

<mat-card class="card-class">
    <mat-card-header>
        <mat-card-title>Nihira Techiees</mat-card-title>
        <mat-card-subtitle>Angular 15 - Material UI</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-lg-image src="https://angular.io/assets/images/logos/angular/angular.svg"/>
    <mat-card-content>
        <p>
            Angular is an application-design framework and development platform for creating efficient and sophisticated single-page apps.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button><mat-icon>thumb_up</mat-icon> Like</button>
        <button mat-button><mat-icon>share</mat-icon> Share</button>
    </mat-card-actions>
    <mat-card-footer></mat-card-footer>
</mat-card>

<br>
<mat-card class="card-class">
    <mat-card-header>
        <mat-card-title>Nihira Techiees</mat-card-title>
        <mat-card-subtitle>Angular 15 - Material UI</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="https://angular.io/assets/images/logos/angular/angular.svg"/>
    <mat-card-content>
        <p>
            Angular is an application-design framework and development platform for creating efficient and sophisticated single-page apps.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button><mat-icon>thumb_up</mat-icon> Like</button>
        <button mat-button><mat-icon>share</mat-icon> Share</button>
    </mat-card-actions>
    <mat-card-footer></mat-card-footer>
</mat-card>
