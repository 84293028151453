<!-- <div *ngIf="custdata[0].length > 0"> -->
<form [formGroup]="formDosen">
    <h1 mat-dialog-title>{{inputdata.title}}</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="Name" value="" formControlName="name" readonly="readonly">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="email" matInput placeholder="Email" value="" formControlName="email" readonly="readonly">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Nidn</mat-label>
            <input type="text" matInput placeholder="Nidn" value="" formControlName="nidn" readonly="readonly">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button (click)="closepopup()" mat-raised-button color="warn">Close</button>
        <!-- <button mat-raised-button [mat-dialog-close]="closemessage" color="accent">Cancel</button> -->
    </div>
</form>
<!-- </div> -->