<mat-card>
    <mat-card-content class="table-responsive">
        <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="no">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> No.</th>
                <td mat-cell *matCellDef="let element; let i = index">{{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="namapeminatan">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Peminatan</th>
                <td mat-cell *matCellDef="let element">{{element.namaPeminatan}}</td>
            </ng-container>

            <ng-container matColumnDef="urutanMinat">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Urutan Peminatan</th>
                <td mat-cell *matCellDef="let element">{{element.urutanMinat}}</td>
            </ng-container>

            <ng-container matColumnDef="namamatkul1">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mata Kuliah</th>
                <td mat-cell *matCellDef="let element">{{element.namaMatkul1}}</td>
            </ng-container>

            <ng-container matColumnDef="nilaimatkul1">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nilai Matkul</th>
                <td mat-cell *matCellDef="let element">{{element.nilaiMatkul1}}</td>
            </ng-container>

            <ng-container matColumnDef="namamatkul2">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mata Kuliah</th>
                <td mat-cell *matCellDef="let element">{{element.namaMatkul2}}</td>
            </ng-container>

            <ng-container matColumnDef="nilaimatkul2">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nilai Matkul</th>
                <td mat-cell *matCellDef="let element">{{element.nilaiMatkul2}}</td>
            </ng-container>

            <ng-container matColumnDef="namamatkul3">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mata Kuliah</th>
                <td mat-cell *matCellDef="let element">{{element.namaMatkul3}}</td>
            </ng-container>

            <ng-container matColumnDef="nilaimatkul3">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nilai Matkul</th>
                <td mat-cell *matCellDef="let element">{{element.nilaiMatkul3}}</td>
            </ng-container>

            <ng-container matColumnDef="namamatkul4">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mata Kuliah</th>
                <td mat-cell *matCellDef="let element">{{element.namaMatkul4}}</td>
            </ng-container>

            <ng-container matColumnDef="nilaimatkul4">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nilai Matkul</th>
                <td mat-cell *matCellDef="let element">{{element.nilaiMatkul4}}</td>
            </ng-container>

            <ng-container matColumnDef="namamatkul5">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nama Mata Kuliah</th>
                <td mat-cell *matCellDef="let element">{{element.namaMatkul5}}</td>
            </ng-container>

            <ng-container matColumnDef="nilaimatkul5">
                <th mat-sort-header mat-header-cell *matHeaderCellDef> Nilai Matkul</th>
                <td mat-cell *matCellDef="let element">{{element.nilaiMatkul5}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action</th>
                <td mat-cell *matCellDef="let element">

                    <!-- <button (click)="editcustomer(element.id)" mat-raised-button color="primary">Edit</button> -->
                    <button (click)="deletePeminatanMahasiswa(element.id)" mat-raised-button
                        color="warn">Delete</button>
                    <!-- <button (click)="detailcustomer(element.id)" mat-raised-button color="primary">Detail</button> -->
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>

        </table>

    </mat-card-content>
    <br>
    <mat-card-footer>

        <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>

    </mat-card-footer>
</mat-card>