import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { PeminatanMahasiswa } from '../Model/peminatan-mahasiswa';
import { User } from '../Model/user';
import { ErrorHandlerService } from './error-handler.service';
import { NilaiPeminatanMahasiswa } from '../Model/nilai-peminatan-mahasiswa';

@Injectable({
  providedIn: 'root',
})
export class PeminatanMahasiswaService {
  private url = 'https://api.simitafapetub.site/peminatanmahasiswa';
  // 'http://localhost:4000/peminatanmahasiswa';

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  fetchAll(): Observable<PeminatanMahasiswa[]> {
    return this.http
      .get<PeminatanMahasiswa[]>(this.url, { responseType: 'json' })
      .pipe(
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa[]>(
            'fetchAll',
            []
          )
        )
      );
  }

  fetchByUserId(userId: Pick<User, 'id'>): Observable<{}> {
    return this.http
      .get<PeminatanMahasiswa>(`${this.url}/${userId}`, this.httpOptions)
      .pipe(
        first(),
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa>(
            'getPeminatanByUserId'
          )
        )
      );
  }

  // fetchNamaPeminatan(): Observable<PeminatanMahasiswa[]> {
  //   return this.http
  //     .get<PeminatanMahasiswa[]>(
  //       'http://localhost:4000/middlewarepeminatanmahasiswa',
  //       { responseType: 'json' }
  //     )
  //     .pipe(
  //       catchError(
  //         this.errorHandlerService.handleError<PeminatanMahasiswa[]>(
  //           'fetchNamaPeminatan',
  //           []
  //         )
  //       )
  //     );
  // }

  fetchDataTablePeminatanMahasiswa(
    idUser: number
  ): Observable<PeminatanMahasiswa[]> {
    return this.http
      .get<PeminatanMahasiswa[]>(
        `https://api.simitafapetub.site/middlewarepeminatanmahasiswa/${idUser}`,
        // `http://localhost:4000/middlewarepeminatanmahasiswa/${idUser}`,
        this.httpOptions
      )
      .pipe(
        first(),
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa[]>(
            'fetchDataTablePeminatanMahasiswa',
            []
          )
        )
      );
  }

  addPeminatanMahasiswa(
    formData: Partial<PeminatanMahasiswa>,
    idUser: Pick<User, 'id'>
  ): Observable<PeminatanMahasiswa> {
    return this.http
      .post<PeminatanMahasiswa>(
        this.url,
        {
          idPeminatan: formData.idPeminatan,
          urutanMinat: formData.urutanMinat,
          idUser: idUser,
        },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa>(
            'addPeminatanMahasiswa'
          )
        )
      );
  }

  deletePeminatanMahasiswa(
    idPeminatanMahasiswa: Pick<PeminatanMahasiswa, 'id'>
  ): Observable<{}> {
    return this.http
      .delete<PeminatanMahasiswa>(
        `${this.url}/${idPeminatanMahasiswa}`,
        this.httpOptions
      )
      .pipe(
        first(),
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa>(
            'deletePeminatanMahasiswa'
          )
        )
      );
  }

  updateIsFinalSubmit(
    formData: Partial<PeminatanMahasiswa>,
    idPeminatanMahasiswa: Pick<PeminatanMahasiswa, 'id'>
  ): Observable<PeminatanMahasiswa> {
    var intFinalSubmit = formData.isFinalSubmit ? 1 : 0;
    return this.http
      .patch<PeminatanMahasiswa>(
        `${this.url}/${idPeminatanMahasiswa}`,
        {
          isFinalSubmit: intFinalSubmit,
        },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<PeminatanMahasiswa>(
            'updateIsFinalSubmit'
          )
        )
      );
  }
}
