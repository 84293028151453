import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/service/auth.service';
import { RekapitulasiPeminatanService } from 'src/app/service/rekapitulasi-peminatan.service';
import { RekapitulasiPeminatan } from 'src/app/Model/rekapitulasi-peminatan';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Peminatan } from 'src/app/Model/peminatan';
import { first, Observable } from 'rxjs';
import { PeminatanService } from 'src/app/service/peminatan.service';

@Component({
  selector: 'app-rekapitulasi-peminatan',
  templateUrl: './rekapitulasi-peminatan.component.html',
  styleUrls: ['./rekapitulasi-peminatan.component.css']
})

export class RekapitulasiPeminatanComponent implements OnInit {
  formGeneratePeminatan!: FormGroup;
  peminatan$!: Observable<Peminatan[]>;
  listRekapitulasiPeminatan!: RekapitulasiPeminatan[];
  dataSource: any;
  decodedToken: any;
  displayedColumns: string[] = [
    'no',
    'namaMahasiswa',
    'ipk',
    'namaPeminatan',
    'urutanMinat',
    'suratRekomendasi'
  ];
  @ViewChild(MatPaginator) paginatior!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private authService: AuthService,
    private rekapitulasiPeminatanService: RekapitulasiPeminatanService,
    private peminatanService: PeminatanService,
    private dialog: MatDialog
  ) {
    this.loadDataRekapitulasiPeminatan();
  }

  ngOnInit(): void {
    this.decodedToken = this.authService.decodeToken();
    this.peminatan$ = this.fetchAllPeminatan();
    this.formGeneratePeminatan = this.generatePeminatanFormGroup();
  }

  generatePeminatanFormGroup(): FormGroup {
    return new FormGroup({
      nama: new FormControl(''),
    });
  }

  fetchAllPeminatan(): Observable<Peminatan[]> {
    return this.peminatanService.fetchAll();
  }
  
  loadDataRekapitulasiPeminatan() {
    this.rekapitulasiPeminatanService.fetchAllRekapitulasiPeminatan().subscribe((res) => {
      this.listRekapitulasiPeminatan = res;
      this.dataSource = new MatTableDataSource<RekapitulasiPeminatan>(this.listRekapitulasiPeminatan);
      this.dataSource.paginator = this.paginatior;
      this.dataSource.sort = this.sort;
    });
  }

  // loadDataRekapitulasiPeminatan(namaPeminatan: string) {
  //   this.rekapitulasiPeminatanService.fetchRekapitulasiPeminatan(namaPeminatan).subscribe((res) => {
  //     this.listRekapitulasiPeminatan = res;
  //     this.dataSource = new MatTableDataSource<RekapitulasiPeminatan>(this.listRekapitulasiPeminatan);
  //     this.dataSource.paginator = this.paginatior;
  //     this.dataSource.sort = this.sort;
  //   });
  // }

  filterChange(data: Event) {
    const value = (data.target as HTMLInputElement).value;
    this.dataSource.filter = value;
  }

  onSubmitGeneratePeminatan(
    formGeneratePeminatan: Pick<
      Peminatan,
      | 'nama'>
  ): void {
    this.rekapitulasiPeminatanService
      .plottingPeminatanMahasiswaBasedOnKuotaPeminatan(
        formGeneratePeminatan
      )
      .pipe(first())
      .subscribe(() => {
        // this.create.emit(null);
        // this.loadDataRekapitulasiPeminatan(formGeneratePeminatan.nama);
        this.loadDataRekapitulasiPeminatan();
      });

    // this.formNilaiPeminatanMahasiswa.reset();
    // this.formDirective.resetForm();
  }

  generatePeminatanBasedOnHaveRecommendation() : void {
    this.rekapitulasiPeminatanService
      .plottingPeminatanMahasiswaBasedOnHaveRecommendation()
      .pipe(first())
      .subscribe(() => {
        this.loadDataRekapitulasiPeminatan();
      });
  }
}
