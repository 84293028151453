<!-- <mat-form-field class="mat-50">
    <mat-label>Name</mat-label>
    <input matInput value="{{ decodedToken.name }}" readonly="readonly" [(ngModel)]="decodedToken.name"
        readonly="readonly" />
</mat-form-field>
<mat-form-field class="mat-50">
    <mat-label>NIM</mat-label>
    <input matInput />
</mat-form-field> -->
<!-- <mat-card>
    <mat-card-content>
        <button (click)="isOpen = !isOpen" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>Tambah Peminatan
        </button>
        <form *ngIf="isOpen" [formGroup]="formPeminatanMahasiswa" #formDirective="ngForm"
            (ngSubmit)="onSubmitPeminatanMahasiswa(formPeminatanMahasiswa.value)" novalidate>
            <mat-form-field>
                <mat-label>IPK</mat-label>
                <input matInput formControlName="ipk" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Pilih Peminatan</mat-label>
                <mat-select name="pilihanPeminatan" [formControlName]="'pilihanPeminatan'" id="pemilihanPeminatan"
                    name="pemilihanPeminatan">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of peminatan$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="formPeminatanMahasiswa.get('pilihanPeminatan')?.value == 3">
    <mat-card-content>
        <h2>Nilai Mata Kuliah Wajib Minat Produksi Ternak</h2>
        <form [formGroup]="formNilaiMataKuliah" (ngSubmit)="onSubmitNilaiMataKuliah(formNilaiMataKuliah.value)"
            novalidate>
            <mat-form-field class="mat-50">
                <mat-label>Pilih Mata Kuliah</mat-label>
                <mat-select name="mataKuliah" formControlName="mataKuliah">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-50">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilai" formControlName="nilai">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="formPeminatanMahasiswa.get('pilihanPeminatan')?.value == 4">
    <mat-card-content>
        <h2>Nilai Mata Kuliah Wajib Minat Nutrisi dan Makanan Ternak</h2>
        <form [formGroup]="formNilaiMataKuliah" #formDirective="ngForm"
            (ngSubmit)="onSubmitNilaiMataKuliah(formNilaiMataKuliah.value)" novalidate>
            <mat-form-field class="mat-50">
                <mat-label>Pilih Mata Kuliah</mat-label>
                <mat-select name="mataKuliah" formControlName="mataKuliah">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-50">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilai" formControlName="nilai">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="formPeminatanMahasiswa.get('pilihanPeminatan')?.value == 5">
    <mat-card-content>
        <h2>Nilai Mata Kuliah Wajib Minat Sosial Ekonomi Peternakan</h2>
        <form [formGroup]="formNilaiMataKuliah" #formDirective="ngForm"
            (ngSubmit)="onSubmitNilaiMataKuliah(formNilaiMataKuliah.value)" novalidate>
            <mat-form-field class="mat-50">
                <mat-label>Pilih Mata Kuliah</mat-label>
                <mat-select name="mataKuliah" formControlName="mataKuliah">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-50">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilai" formControlName="nilai">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="formPeminatanMahasiswa.get('pilihanPeminatan')?.value == 6">
    <mat-card-content>
        <h2>Nilai Mata Kuliah Wajib Minat Teknologi Hasil Ternak</h2>
        <form [formGroup]="formNilaiMataKuliah" #formDirective="ngForm"
            (ngSubmit)="onSubmitNilaiMataKuliah(formNilaiMataKuliah.value)" novalidate>
            <mat-form-field class="mat-50">
                <mat-label>Pilih Mata Kuliah</mat-label>
                <mat-select name="mataKuliah" formControlName="mataKuliah">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-50">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilai" formControlName="nilai">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="formPeminatanMahasiswa.get('pilihanPeminatan')?.value == 8">
    <mat-card-content>
        <h2>Nilai Mata Kuliah Wajib Minat Reproduksi dan Pemuliaan Ternak</h2>
        <form [formGroup]="formNilaiMataKuliah" #formDirective="ngForm"
            (ngSubmit)="onSubmitNilaiMataKuliah(formNilaiMataKuliah.value)" novalidate>
            <mat-form-field class="mat-50">
                <mat-label>Pilih Mata Kuliah</mat-label>
                <mat-select name="mataKuliah" formControlName="mataKuliah">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-50">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilai" formControlName="nilai">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card> -->

<mat-card>
    <mat-card-content>
        <h2>Pilihlah urutan minat 1 - 5 sesuai prioritas saudara.</h2>
        <button (click)="isOpen = !isOpen" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>Tambah Peminatan
        </button>
        <form *ngIf="isOpen" [formGroup]="formNilaiPeminatanMahasiswa" #formDirective="ngForm"
            (ngSubmit)="onSubmitNilaiPeminatanMahasiswa(formNilaiPeminatanMahasiswa.value)" novalidate>
            <mat-form-field class="mat-75">
                <mat-label>Pilih Peminatan</mat-label>
                <mat-select name="idPeminatan" [formControlName]="'idPeminatan'" id="idPeminatan"
                    name="idPeminatan" (valueChange)="selectPeminatan($event)" [(value)]="selectedPeminatan">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of peminatan$ | async" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25">
                <mat-label>Urutan Peminatan</mat-label>
                <mat-select name="urutanMinat" formControlName="urutanMinat" id="urutanMinat"
                    name="urutanMinat">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of peminatan$ | async; let i = index" [value]="i+1">{{i+1}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-75"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Pilih Mata Kuliah Wajib Minat</mat-label>
                <mat-select name="idMatkul1" formControlName="idMatkul1">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilaiMatkul1" formControlName="nilaiMatkul1">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-75"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Pilih Mata Kuliah Wajib Minat</mat-label>
                <mat-select name="idMatkul2" formControlName="idMatkul2">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilaiMatkul2" formControlName="nilaiMatkul2">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-75"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Pilih Mata Kuliah Wajib Minat</mat-label>
                <mat-select name="idMatkul2" formControlName="idMatkul3">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilaiMatkul2" formControlName="nilaiMatkul3">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-75"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Pilih Mata Kuliah Wajib Minat</mat-label>
                <mat-select name="idMatkul4" formControlName="idMatkul4">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilaiMatkul4" formControlName="nilaiMatkul4">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-75"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Pilih Mata Kuliah Wajib Minat</mat-label>
                <mat-select name="idMatkul5" formControlName="idMatkul5">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option *ngFor="let item of mataKuliah$" [value]="item.id">{{ item.nama }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat-25"
                *ngIf="formNilaiPeminatanMahasiswa.get('idPeminatan')?.value == selectedPeminatan">
                <mat-label>Nilai</mat-label>
                <mat-select name="nilaiMatkul5" formControlName="nilaiMatkul5">
                    <mat-option value="none" disabled="disabled">None</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C+">C+</mat-option>
                    <mat-option value="D">D</mat-option>
                    <mat-option value="E">E</mat-option>
                </mat-select>
            </mat-form-field>

            <button color="accent" mat-raised-button type="submit">
                <span>Submit</span>
            </button>
        </form>
    </mat-card-content>
</mat-card>