<h1>Nihira Techiees</h1>
<h2>Angular 15 - Material UI Tutorial</h2>
<div>
<button matBadge="2" matBadgeColor="accent" matBadgeOverlap="false" mat-raised-button color="primary">Welcome</button>
</div>
<br>
<div>
    <button matBadge="2" matBadgeColor="accent" matBadgeSize="small" mat-raised-button color="primary">Welcome</button>
</div>
<br>
<div>
    <button matBadge="2" matBadgeHidden="false" matBadgeColor="accent" matBadgePosition="before" matBadgeOverlap="false" matBadgeSize="large" mat-raised-button color="primary">Welcome</button>
</div>
<br>
<mat-icon matBadge="3">home</mat-icon>
