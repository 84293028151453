import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { ErrorHandlerService } from './error-handler.service';
import { RekapitulasiPeminatan } from '../Model/rekapitulasi-peminatan';
import { Peminatan } from '../Model/peminatan';

@Injectable({
  providedIn: 'root',
})
export class RekapitulasiPeminatanService {
  private url = 
  'https://api.simitafapetub.site/rekapitulasipeminatan';
  // 'http://localhost:4000/rekapitulasipeminatan';

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  fetchRekapitulasiPeminatan(
    namaPeminatan: string
  ): Observable<RekapitulasiPeminatan[]> {
    return this.http
      .post<RekapitulasiPeminatan[]>(
        this.url,
        {
          namaPeminatan: namaPeminatan,
        },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<RekapitulasiPeminatan[]>(
            'fetchRekapitulasiPeminatan'
          )
        )
      );
  }

  plottingPeminatanMahasiswaBasedOnHaveRecommendation(): Observable<RekapitulasiPeminatan> {
    return this.http
      .post<RekapitulasiPeminatan>(
        `${this.url}/plottingPeminatanMahasiswaBasedOnHaveRecommendation`,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<RekapitulasiPeminatan>(
            'plottingPeminatanMahasiswaBasedOnHaveRecommendation'
          )
        )
      );
  }

  plottingPeminatanMahasiswaBasedOnKuotaPeminatan(
    formData: Partial<Peminatan>
  ): Observable<RekapitulasiPeminatan> {
    console.log('nama peminatan: ' + formData.nama);
    return this.http
      .post<RekapitulasiPeminatan>(
        `${this.url}/plottingPeminatanMahasiswaBasedOnKuotaPeminatan`,
        {
          namaPeminatan: formData.nama,
        },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<RekapitulasiPeminatan>(
            'plottingPeminatanMahasiswaBasedOnKuotaPeminatan'
          )
        )
      );
  }

  fetchAllRekapitulasiPeminatan(): Observable<RekapitulasiPeminatan[]> {
    return this.http
      .get<RekapitulasiPeminatan[]>(this.url, { responseType: 'json' })
      .pipe(
        catchError(
          this.errorHandlerService.handleError<RekapitulasiPeminatan[]>(
            'fetchAllRekapitulasiPeminatan',
            []
          )
        )
      );
  }
}
