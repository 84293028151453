<h2>Angular Material - Input</h2>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Enter text" />
</mat-form-field>


<form class="example-form">
    <mat-form-field class="example-full-width">
      <mat-label>Favorite food</mat-label>
      <input matInput placeholder="Ex. Pizza" value="Sushi">
    </mat-form-field>
  
    <mat-form-field class="example-full-width">
      <mat-label>Leave a comment</mat-label>
      <mat-select></mat-select>
    </mat-form-field>
  </form>