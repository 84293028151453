<form [formGroup]="formMahasiswa" (ngSubmit)="submitForm()">
    <h1 mat-dialog-title>{{inputdata.title}}</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="text" matInput placeholder="New Password" formControlName="password">
          </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary">Save</button>
        <a (click)="closePopUp()" mat-raised-button color="warn">Close</a>
    </div>
</form>