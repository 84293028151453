<!-- <div *ngIf="custdata[0].length > 0"> -->
<form [formGroup]="formPeminatan">
    <h1 mat-dialog-title>{{inputdata.title}}</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="Name" value="" formControlName="nama" readonly="readonly">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button (click)="closepopup()" mat-raised-button color="warn">Close</button>
        <!-- <button mat-raised-button [mat-dialog-close]="closemessage" color="accent">Cancel</button> -->
    </div>
</form>
<!-- </div> -->