<mat-toolbar color="primary" class="mat-elevation-z8">
    <button (click)="drawer.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
    </button>
    <span>Simita</span>
    <span class="example-spacer"></span>
    <button [matMenuTriggerFor]="lvl1master" mat-button>
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #lvl1master="matMenu">
        <button routerLink="userprofile" mat-menu-item>Informasi User</button>
        <button (click)="updatePassword(decodedToken.userId)" mat-menu-item>Ubah Password</button>
    </mat-menu>

    <span *ngIf="isAuthenticated">Selamat Datang, {{ decodedToken.name }} </span>
    

    <!-- <button routerLink="" mat-button>Home</button> -->
    <!-- <button [matMenuTriggerFor]="lvl1master" mat-button>Master</button>
    <mat-menu #lvl1master="matMenu">
        <button [matMenuTriggerFor]="company" mat-menu-item>Company</button>
        <button [matMenuTriggerFor]="customer" mat-menu-item>Customer</button>
        <button [matMenuTriggerFor]="product" mat-menu-item>Product</button>
    </mat-menu>

    <mat-menu #company="matMenu">
        <button mat-menu-item>Term</button>
        <button mat-menu-item>Tax</button>
        <button mat-menu-item>Currency</button>
    </mat-menu>
    <mat-menu #customer="matMenu">
        <button mat-menu-item>Type</button>
        <button mat-menu-item>Tax</button>
    </mat-menu>
    <mat-menu #product="matMenu">
        <button mat-menu-item>Category</button>
        <button [matMenuTriggerFor]="variant" mat-menu-item>Variant</button>
        <button mat-menu-item>UOM</button>
    </mat-menu>

    <mat-menu #variant="matMenu">
        <button mat-menu-item>Color</button>
        <button mat-menu-item>Size</button>
    </mat-menu> -->

    <!-- <button routerLink="input" mat-button>Input</button>
    <button routerLink="autocomplete" mat-button>Autocomplete</button> -->
    <a class="button" *ngIf="isAuthenticated" (click)="logout()" mat-raised-button color="accent">Logout</a>
    <!-- <button (click)="badgevisibility()"  mat-icon-button>
        <mat-icon matBadgeColor="accent" matBadge="15" [matBadgeHidden]="badgevisible" matBadgeSize="small">notifications</mat-icon>
    </button> -->
</mat-toolbar>
<mat-drawer-container autosize>
    <mat-drawer #drawer class="parent" opened="true" mode="side" position="start">
    <mat-nav-list>
        <!-- <mat-list-item>
            <button routerLink="dashboard" mat-button><mat-icon>dashboard</mat-icon> Dashboard</button>
        </mat-list-item> -->
        <!-- <mat-list-item>
            <button routerLink="" mat-button><mat-icon>home</mat-icon> Home</button>
        </mat-list-item> -->
        <!-- <mat-list-item *ngIf="decodedToken.role != 'admin'">
            <button routerLink="userprofile" mat-button><mat-icon>explore</mat-icon> User Profile</button>
        </mat-list-item> -->
        <mat-list-item *ngIf="decodedToken.role != 'admin'">
            <button routerLink="peminatanmahasiswa" mat-button><mat-icon>assignment_ind</mat-icon> Peminatan</button>
        </mat-list-item>
        <!-- <mat-list-item *ngIf="decodedToken.role != 'admin'">
            <button routerLink="pengecekanjudulpenelitian" mat-button><mat-icon>explore</mat-icon> Pengecekan Judul Penelitian</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role != 'admin'">
            <button routerLink="pengajuanjudulpenelitian" mat-button><mat-icon>explore</mat-icon> Pengajuan Judul Penelitian</button>
        </mat-list-item> -->
        <!-- <mat-list-item>
            <button routerLink="slider" mat-button><mat-icon>explore</mat-icon> Slider</button>
        </mat-list-item> -->
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="mastermahasiswa" mat-button><mat-icon>table</mat-icon> Master Mahasiswa</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="masterdosen" mat-button><mat-icon>table</mat-icon> Master Dosen</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="masterpeminatan" mat-button><mat-icon>table</mat-icon> Master Peminatan</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="mastermatakuliah" mat-button><mat-icon>table</mat-icon> Master Mata Kuliah</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="rekapitulasipeminatan" mat-button><mat-icon>table</mat-icon> Rekapitulasi Peminatan</button>
        </mat-list-item>
        <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="approvalpengajuanjudulpenelitian" mat-button><mat-icon>table</mat-icon> Approval Pengajuan Judul Penelitian</button>
        </mat-list-item>
        <!-- <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="table" mat-button><mat-icon>table</mat-icon> Table</button>
        </mat-list-item> -->
        <!-- <mat-list-item *ngIf="decodedToken.role == 'admin'">
            <button routerLink="table" mat-button><mat-icon>table</mat-icon> Table</button>
        </mat-list-item> -->
        <!-- <mat-list-item>
            <button mat-button><mat-icon>settings</mat-icon> Settings</button>
        </mat-list-item>
        <mat-list-item>
            <button mat-button><mat-icon>help</mat-icon> Help</button>
        </mat-list-item>
        <mat-list-item>
            <button mat-button><mat-icon>analytics</mat-icon> analytics</button>
        </mat-list-item> -->
    </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content class="content">
        <div style="text-align: center;min-height: 600px;">

            <router-outlet></router-outlet>
            </div>
    </mat-drawer-content>
</mat-drawer-container>