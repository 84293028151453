import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/Model/user';
import { MasterMahasiswaService } from 'src/app/service/master-mahasiswa.service';

@Component({
  selector: 'app-modal-update-password',
  templateUrl: './modal-update-password.component.html',
  styleUrls: ['./modal-update-password.component.css'],
})
export class ModalUpdatePasswordComponent implements OnInit {
  inputdata: any;
  editdata: any;
  closemessage = 'closed using directive';
  formMahasiswa!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<ModalUpdatePasswordComponent>,
    private masterMahasiswaService: MasterMahasiswaService
  ) {}
  ngOnInit(): void {
    this.inputdata = this.data;

    this.formMahasiswa = this.mahasiswaFormGroup();
  }

  closePopUp() {
    this.ref.close('Closed using function');
  }

  mahasiswaFormGroup(): FormGroup {
    return new FormGroup({
      password: new FormControl(''),
    });
  }

  submitForm() {
    this.onSubmitUpdatePassword(this.formMahasiswa.value, this.inputdata.id);
  }

  onSubmitUpdatePassword(
    formMahasiswa: Pick<User, 'password'>,
    userId: Pick<User, 'id'>
  ): void {
    this.masterMahasiswaService
      .updatePassword(formMahasiswa, userId)
      .subscribe(() => {
        alert('Update password berhasil!');
        this.closePopUp();
      });
  }
}
