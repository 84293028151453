import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Peminatan } from 'src/app/Model/peminatan';
import { User } from 'src/app/Model/user';
import { AuthService } from 'src/app/service/auth.service';
import { PeminatanService } from 'src/app/service/peminatan.service';
import { PeminatanMahasiswaService } from 'src/app/service/peminatan-mahasiswa.service';
import { PeminatanMahasiswa } from 'src/app/Model/peminatan-mahasiswa';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDeletePeminatanMahasiswaComponent } from '../modal-delete-peminatan-mahasiswa/modal-delete-peminatan-mahasiswa.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataPeminatanMahasiswa } from 'src/app/Model/data-peminatan-mahasiswa';
import { DataPeminatanMahasiswaService } from 'src/app/service/data-peminatan-mahasiswa.service';

@Component({
  selector: 'app-create-peminatan-mahasiswa',
  templateUrl: './create-peminatan-mahasiswa.component.html',
  styleUrls: ['./create-peminatan-mahasiswa.component.css'],
})
export class CreatePeminatanMahasiswaComponent implements OnInit {
  @ViewChild(MatPaginator) paginatior!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('formDirective') formDirective!: NgForm;
  @Output() create: EventEmitter<any> = new EventEmitter();

  formPeminatanMahasiswa!: FormGroup;
  formDataPeminatanMahasiswa!: FormGroup;
  formUploadFileSuratRekomendasi!: FormGroup;
  decodedToken: any;
  peminatan$!: Observable<Peminatan[]>;
  userId!: Pick<User, 'id'>;
  selectedPeminatan: any;
  dataSource: any;
  peminatanMahasiswaList!: PeminatanMahasiswa[];
  peminatanMahasiswa$!: any;
  displayedColumns: string[] = [
    'no',
    'namapeminatan',
    'urutanMinat',
    // 'haveRecommendation',
    // 'isFinalSubmit',
    'action',
  ];

  isOpen = false;

  constructor(
    private authService: AuthService,
    private peminatanService: PeminatanService,
    private peminatanMahasiswaService: PeminatanMahasiswaService,
    private dataPeminatanMahasiswaService: DataPeminatanMahasiswaService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.decodedToken = this.authService.decodeToken();
    this.peminatan$ = this.fetchAllPeminatan();
    this.userId = this.authService.userId;
    this.formPeminatanMahasiswa = this.createPeminatanFormGroup();
    this.formDataPeminatanMahasiswa = this.createDataPeminatanFormGroup();
    this.formUploadFileSuratRekomendasi =
      this.createUploadFilePeminatanFormGroup();
    this.peminatanMahasiswa$ = this.loadPeminatanMahasiswa(
      this.decodedToken.userId
    );
  }

  fetchAllPeminatan(): Observable<Peminatan[]> {
    return this.peminatanService.fetchAll();
  }

  createPeminatanFormGroup(): FormGroup {
    return new FormGroup({
      idPeminatan: new FormControl('', [Validators.required]),
      urutanMinat: new FormControl('', [Validators.required]),
    });
  }

  createDataPeminatanFormGroup(): FormGroup {
    return new FormGroup({
      haveRecommendation: new FormControl('', [Validators.required]),
      isFinalSubmit: new FormControl('', [
        (control) => {
          return !control.value ? { required: true } : null;
        },
      ]),
      buktiSuratRekomendasi: new FormControl(null),
    });
  }

  createUploadFilePeminatanFormGroup(): FormGroup {
    return new FormGroup({
      buktiSuratRekomendasi: new FormControl(null),
      buktiKhs: new FormControl(null),
    });
  }

  onSubmitPeminatanMahasiswa(
    formPeminatanMahasiswa: Pick<
      PeminatanMahasiswa,
      'idPeminatan' | 'urutanMinat'
    >
  ): void {
    console.log(formPeminatanMahasiswa);
    this.peminatanMahasiswaService
      .addPeminatanMahasiswa(formPeminatanMahasiswa, this.authService.userId)
      .pipe(first())
      .subscribe(() => {
        // this.create.emit(null);
        this.loadPeminatanMahasiswa(this.decodedToken.userId);
      });

    this.formPeminatanMahasiswa.reset();
    this.formDirective.resetForm();
  }

  onSubmitDataPeminatanMahasiswa(
    formDataPeminatanMahasiswa: Pick<
      DataPeminatanMahasiswa,
      'haveRecommendation' | 'isFinalSubmit' | 'buktiSuratRekomendasi'
    >
  ): void {
    console.log(formDataPeminatanMahasiswa);
    this.dataPeminatanMahasiswaService
      .addDataPeminatanMahasiswa(
        formDataPeminatanMahasiswa,
        this.authService.userId
      )
      .pipe(first())
      .subscribe(() => {
        alert('Upload Berhasil!');
        // this.create.emit(null);
        // this.loadPeminatanMahasiswa(this.decodedToken.userId)
      });

    // this.formPeminatanMahasiswa.reset();
    // this.formDirective.resetForm();
  }

  onSubmitUploadFileSuratRekomendasi(
    formUploadFileSuratRekomendasi: Pick<
      DataPeminatanMahasiswa,
      'buktiSuratRekomendasi'
    >
  ): void {
    this.dataPeminatanMahasiswaService
      .uploadFileSuratRekomendasi(
        formUploadFileSuratRekomendasi
      )
      .pipe(first())
      .subscribe(() => {
        alert('Upload Berhasil!');
        // this.create.emit(null);
        // this.loadPeminatanMahasiswa(this.decodedToken.userId)
      });

    // this.formPeminatanMahasiswa.reset();
    // this.formDirective.resetForm();
  }

  deletePeminatanMahasiswa(id: any) {
    this.openPopUp(
      id,
      'Delete Peminatan Mahasiswa',
      ModalDeletePeminatanMahasiswaComponent
    );
  }

  openPopUp(id: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      width: '40%',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '1000ms',
      data: {
        title: title,
        id: id,
      },
    });
    _popup.afterClosed().subscribe((item) => {
      this.loadPeminatanMahasiswa(this.decodedToken.userId);
    });
  }

  loadPeminatanMahasiswa(userId: number) {
    this.peminatanMahasiswaService
      .fetchDataTablePeminatanMahasiswa(userId)
      .subscribe((res) => {
        this.peminatanMahasiswaList = res;
        this.dataSource = new MatTableDataSource<PeminatanMahasiswa>(
          this.peminatanMahasiswaList
        );
        this.dataSource.paginator = this.paginatior;
        this.dataSource.sort = this.sort;
      });
  }

  onImagePicked(event: Event): void {
    const file = (event.target as HTMLInputElement)?.files?.[0]; // Here we use only the first file (single file)
    this.formUploadFileSuratRekomendasi.patchValue({ buktiSuratRekomendasi: file });
  }
}
