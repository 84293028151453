import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/service/auth.service';
import { ModalUpdatePasswordComponent } from '../modal-update-password/modal-update-password.component';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {
  isAuthenticated = false;
  userDisplayName: any;
  decodedToken: any;

  badgevisible = false;
  badgevisibility() {
    this.badgevisible = true;
  }

  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.authService.isUserLoggedIn$.subscribe((isLoggedIn) => {
      this.isAuthenticated = isLoggedIn;
    });

    this.decodedToken = this.authService.decodeToken();
  }

  logout(): void {
    localStorage.removeItem('token');
    this.authService.isUserLoggedIn$.next(false);
    window.location.reload();
  }

  updatePassword(id: any) {
    console.log("id: " + id);
    this.openModalUpdatePassword(id, 'Update Password', ModalUpdatePasswordComponent);
  }

  openModalUpdatePassword(id: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      width: '40%',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '1000ms',
      data: {
        title: title,
        id: id,
      },
    });
    _popup.afterClosed().subscribe();
  }
}
