import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { User } from '../Model/user';
import { ErrorHandlerService } from './error-handler.service';
import { DataPeminatanMahasiswa } from '../Model/data-peminatan-mahasiswa';

@Injectable({
  providedIn: 'root',
})
export class DataPeminatanMahasiswaService {
  private url = 'https://api.simitafapetub.site/datapeminatanmahasiswa';
  // 'http://localhost:4000/datapeminatanmahasiswa';

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  fetchAll(): Observable<DataPeminatanMahasiswa[]> {
    return this.http
      .get<DataPeminatanMahasiswa[]>(this.url, { responseType: 'json' })
      .pipe(
        catchError(
          this.errorHandlerService.handleError<DataPeminatanMahasiswa[]>(
            'fetchAll',
            []
          )
        )
      );
  }

  fetchByUserId(userId: Pick<User, 'id'>): Observable<{}> {
    return this.http
      .get<DataPeminatanMahasiswa>(`${this.url}/${userId}`, this.httpOptions)
      .pipe(
        first(),
        catchError(
          this.errorHandlerService.handleError<DataPeminatanMahasiswa>(
            'fetchByUserId'
          )
        )
      );
  }

  addDataPeminatanMahasiswa(
    formData: Partial<DataPeminatanMahasiswa>,
    idUser: Pick<User, 'id'>
  ): Observable<DataPeminatanMahasiswa> {
    var intHaveRecommendation = formData.haveRecommendation === 'ya' ? 1 : 0;
    var intFinalSubmit = formData.isFinalSubmit ? 1 : 0;
    return this.http
      .post<DataPeminatanMahasiswa>(
        this.url,
        {
          haveRecommendation: intHaveRecommendation,
          isFinalSubmit: intFinalSubmit,
          buktiSuratRekomendasi: formData.buktiSuratRekomendasi,
          idUser: idUser,
        },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.errorHandlerService.handleError<DataPeminatanMahasiswa>(
            'addDataPeminatanMahasiswa'
          )
        )
      );
  }

  deleteDataPeminatanMahasiswa(
    idDataPeminatanMahasiswa: Pick<DataPeminatanMahasiswa, 'id'>
  ): Observable<{}> {
    return this.http
      .delete<DataPeminatanMahasiswa>(
        `${this.url}/${idDataPeminatanMahasiswa}`,
        this.httpOptions
      )
      .pipe(
        first(),
        catchError(
          this.errorHandlerService.handleError<DataPeminatanMahasiswa>(
            'deleteDataPeminatanMahasiswa'
          )
        )
      );
  }

  uploadFileSuratRekomendasi(formData: Partial<DataPeminatanMahasiswa>) {
    let fd = new FormData();
    if(formData.buktiSuratRekomendasi) {
      fd.append('buktiSuratRekomendasi', formData.buktiSuratRekomendasi, formData.buktiSuratRekomendasi.name);
    }
    return this.http.post(`${this.url}/upload`, fd);
  }

  uploadFileBuktiKhs(formData: Partial<DataPeminatanMahasiswa>) {
    let fd = new FormData();
    if(formData.buktiKhs) {
      fd.append('buktiSuratRekomendasi', formData.buktiKhs, formData.buktiKhs.name);
    }
    return this.http.post(`${this.url}/uploadkhsmahasiswa`, fd);
  }
}
